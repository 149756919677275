import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../../components/layout';
import Helmet from "react-helmet"

export const query = graphql`
    query whitepapers {
        wpcontent {
            whitepapers(first: 99) {
                nodes {
                    id
                    title
                    uri
                    excerpt
                    whitepaperCoverImage
                }
            }
            seo{
                contentTypes {
                    whitepaper {
                        metaDesc
                        title
                    }
                }
            }
        }
    }
`

const whitepapers = ({ data }) => {
    const whitepapers = data.wpcontent.whitepapers.nodes
    const whitepaperSeo = data.wpcontent.seo.contentTypes.whitepaper
    const pageTitle = whitepaperSeo.title
    const metaDesc = whitepaperSeo.metaDesc

    return (
        <Layout>
            <Helmet>
                <title>{pageTitle}</title>

                <meta name="description" content={metaDesc} />

                <meta property="og:title" content={pageTitle} />

                <meta property="og:description" content={metaDesc} />

                <meta property="twitter:title" content={pageTitle} />
                        
                <meta property="twitter:description" content={metaDesc} />
                        
                <meta property="og:type" content="website"/>
                        
                <meta property="og:url" content="https://www.vantagecircle.com/hr-academy/whitepapers/"/>

                <meta property="og:site_name" content="Vantage Circle"/>
                <link rel="canonical" href="https://www.vantagecircle.com/hr-academy/whitepapers/"></link>
            </Helmet>
            <section className="slanted-bg bg-purple-500 mx-auto py-10 pb-2 sm:py-20 sm:pb-0 md:py-40 relative">
                <div className="container max-w-7xl sm:text-center px-6">
                    <h1 className="hero-title-1-white">
                        Our White Paper Library
                    </h1>
                    <p className="section-subtitle-white">
                        Get insightful and in-depth whitepapers on Human Resources to<br/> learn the best practices and build a community where everyone can thrive. 
                    </p>
                </div>
                <img className="absolute z-1 hero-iconsl1 hero-icons-top" src="https://res.cloudinary.com/vantagecircle/image/upload/w_200/gatsbycms/uploads/2021/06/GuidesnEbooks-Hero-L1.png" alt="Guides and Ebooks" />
                <img className="absolute z-1 hero-iconsl2 hero-icons-bottom hero-icons-1" src="https://res.cloudinary.com/vantagecircle/image/upload/w_150/gatsbycms/uploads/2021/06/GuidesnEbooks-Hero-L2.png" alt="Guides and Ebooks" />
                <img className="absolute z-1 hero-iconsr1 hero-icons-top" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2021/06/GuidesnEbooks-Hero-R1.png" alt="Guides and Ebooks" />
                <img className="absolute z-1 hero-iconsr2 hero-icons-bottom hero-icons-1" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2021/06/GuidesnEbooks-Hero-R2.png.png" alt="Guides and Ebooks" />
            </section>
            <section className="guides-list resource-listing container px-6 lg:px-0 max-w-6xl mx-auto pt-5 sm:pt-10 md:py-40 md:-mt-48">
                <div className="mt-10 md:mt-0 grid grid-cols-1 sm:gap-3 md:gap-9 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3">
                    {whitepapers.map(whitepaper => (
                        <article key={whitepaper.id}>
                            <div className="relative z-1 bg-white p-5 card-1 w-full mb-5 md:mb-0 rounded-lg overflow-hidden shadow-lg transform transition duration-500 ease-in-out">
                                <img className="pb-0 rounded-sm" src={whitepaper.whitepaperCoverImage} alt={whitepaper.title}/>
                                <div className="bg-white py-6 pb-2 h-full">
                                    {/* <p className="text-gray-200 text-md mb-2" dangerouslySetInnerHTML={{ __html: webinar.excerpt }} /> */}
                                    <h2 className="box-title font-bold mb-3 line-clamp-2 md:h-14" dangerouslySetInnerHTML={{ __html: whitepaper.title }} />
                                    <div className="box-subtitle line-clamp-3 mb-3" dangerouslySetInnerHTML={{ __html: whitepaper.excerpt }} />
                                    <div className="learnmore-btn mb-0 text1">
                                        <Link className="text-purple-500 inline-flex items-center" to={`${whitepaper.uri}`}>
                                            <span className="a-stroke w-4 bg-purple-500 mr-2 relative"></span>
                                            <span className="text-purple-500 font-medium text-sm uppercase">Read now</span>
                                            <svg className="w-6 h-6 top-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><polygon fill="currentColor" points="15 12 9 16 9 8"></polygon></svg>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </article>
                    ))}
                </div>
            </section>
            {/* <section className="bottom-cta m-6 md:m-0 relative z-1">
                <div className="container max-w-5xl bg-purple-300 rounded-lg shadow-xl p-10 md:p-14 relative z-9 overflow-hidden md:-bottom-10 xl:-bottom-32">
                    <div  className="bottom-cta-content text-center text-white">
                        <div className="section-title-white">Find Out How Vantage Circle Works</div>
                        <div className="mt-5 sm:mt-6 md:mt-6 flex justify-center">
                            <div>
                                <a className="vc-ghost-btn-cta-banner" href="/request-demo/">Schedule a Free Demo</a>
                            </div>
                        </div>
                    </div>
                    <img loading="lazy" className="cta-dot-clip" src="https://res.cloudinary.com/vantagecircle/image/upload/v1622890994/gatsbycms/uploads/2021/06/circle-white-compressed.png"  alt="VC white dots" width="90" height="90"/>
                    <img loading="lazy" className="cta-flower-clip" src="https://res.cloudinary.com/vantagecircle/image/upload/v1622874257/gatsbycms/uploads/2021/06/vc-flower.svg"  alt="VC flower" width="150" height="150"/>
                </div>                         
            </section> */}
            
        </Layout>
    )
}

export default whitepapers

